onComplete(function () {
  const PAGE_SCROLL_THRESHOLD = 100;
  const navHeader = document.querySelector('.nav-header');
  window.addEventListener('scroll', () => {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    if (scroll >= PAGE_SCROLL_THRESHOLD) {
      navHeader.classList.add('scroll');
    } else {
      navHeader.classList.remove('scroll');
    }
  });

  // Mobile Menu
  const hamburger = document.querySelector('.hamburger');
  hamburger.addEventListener('click', event => {
    event.preventDefault();
    hamburger.querySelector('.hambergerIcon').classList.toggle('navbar--open');
    document.querySelector('.navbar-mobile').classList.toggle('navbar--open');
    navHeader.classList.toggle('navbar--open');
    document.body.classList.toggle('fixed');
  });

  // Menublokken
  document.querySelectorAll('.menublokken ul.navlist-nav > li').forEach(menuItem => {
    // If menu item has subitems then add the tabindex attribute.
    const hasSubmenu = !!menuItem.querySelector('ul');
    if (hasSubmenu) {
      menuItem.setAttribute('tabindex', '0');
    }
    menuItem.addEventListener('mouseenter', () => {
      menuItem.querySelector('a.nav-link').classList.add('animated');
    });
    menuItem.addEventListener('mouseleave', () => {
      menuItem.querySelector('a.nav-link').classList.remove('animated');
    });

    // Adding focus and blur events for keyboard accessibility.
    menuItem.addEventListener('focusin', () => {
      menuItem.querySelector('a.nav-link').classList.add('animated');
      const submenu = menuItem.querySelector('ul');
      if (submenu) {
        submenu.classList.add('active');
      }
    });
    menuItem.addEventListener('focusout', () => {
      requestAnimationFrame(() => {
        if (!menuItem.contains(document.activeElement)) {
          menuItem.querySelector('a.nav-link').classList.remove('animated');
          const submenu = menuItem.querySelector('ul');
          if (submenu) {
            submenu.classList.remove('active');
          }
        }
      });
    });
  });

  // Menublokken - Submenus Large
  const navItems = document.querySelectorAll('.menublokken ul.navlist-nav li.nav-item');
  navItems.forEach((navItem, index) => {
    const nestedUl = navItem.querySelector('ul');
    const childCount = nestedUl ? nestedUl.children.length : 0;

    //console.log(`List item ${index + 1} has ${childCount} child(ren) in its nested ul.`);

    // Voeg class aan de parent toe als er meer dan 5 childeren zijn
    if (childCount > 5) {
      navItem.classList.add('menublok-large');
    }
  });

  // Teamleden
  const items = document.querySelectorAll('.team-members [class^="col-"]');
  if (items && items.length) {
    const suffixes = ['name', 'function', 'gebied'];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      for (let k = 0; k < suffixes.length; k++) {
        const suffix = suffixes[k];
        const nameTargets = item.querySelectorAll('[data-text$="-team-member-' + suffix + '"]');
        for (let j = 0; j < nameTargets.length; j++) {
          const nameTarget = nameTargets[j];
          const nameReference = item.querySelector('[data-reference="' + nameTarget.getAttribute('data-text') + '"]');
          if (nameReference) {
            nameTarget.textContent = nameReference.textContent;
          }
        }
      }
    }
  }

  // Trap Focus - Modal
  if (!document.documentElement.hasAttribute('data-logged') || document.documentElement.getAttribute('data-logged') === 'false') {
    document.querySelector('main').addEventListener('focusin', () => {
      const openModals = Array.from(document.querySelectorAll('[role=dialog]'));
      openModals.forEach(modal => {
        if (modal.classList.contains('show')) {
          const buttonToFocus = modal.querySelector('button');
          if (buttonToFocus) {
            buttonToFocus.focus();
          }
        }
      });
    });
  }
});